import DashboardEndPoints from "./endpoint";
import axios from "../ApiService";
import { NetworkConst } from "../NetworkConst";
import DashBoardSummaryModel from "../models/dashboard/dashboardsummary";
import { DashboardGraphDataModel } from "../models/dashboard/dashboardgraphdata";

export const getSummaryApi = async (id: string)  => {
    var res = await axios<DashBoardSummaryModel[]>({
        url: DashboardEndPoints.summary,
        params: { id },
        method: NetworkConst.GET,
        withCredentials: true,
    });
    return res.data
};


export const getGraphDataApi = async (id: string) => {
    var res = await axios<DashboardGraphDataModel[]>({
        url: DashboardEndPoints.graphData,
        params: { id },
        method: NetworkConst.GET,
        withCredentials: true,
    });

    return res.data
};