import { useQuery } from "@tanstack/react-query";
import { Card } from "primereact/card";
import { Column, ColumnBodyOptions } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { TabPanel, TabView } from "primereact/tabview";
import { useEffect, useState } from "react";
import { ApiMethodKeys } from "../../../services/NetworkConst";
import { getPassbookApi, getWalletTopupApi } from "../../../services/wallet/wallet";
import { UserLocalStorage } from "../../../services/models/user/user";
import { WalletTopupsModel } from "../../../services/models/wallet/wallettopup";
import moment from "moment";
import { PassbookDataModel } from "../../../services/models/wallet/passbook";

const CreditHistory = () => {

    return (
        <TabView className="messagecredit flex-1 h-full mh-vh-50">
            <TabPanel header="Topups">
                <Card>
                    <CreditHistoryTable />
                </Card>
            </TabPanel>
            <TabPanel header="Passbook">
                <Card>
                    <PassbookTable />
                </Card>
            </TabPanel>
        </TabView>
    )

}

const CreditHistoryTable = () => {
    const authData: UserLocalStorage = JSON.parse(localStorage.getItem('auth') || '')
    const { data: walletTopupData, refetch } = useQuery<WalletTopupsModel[], Error>({ queryKey: [ApiMethodKeys.GET_WALLET_TOPUP_DATA, authData.user.userID], queryFn: () => getWalletTopupApi(authData.user.userID || "") });
    const [tableData, setTableData] = useState<WalletTopupsModel[]>()

    useEffect(() => {
        setTableData(walletTopupData);
    }, [walletTopupData])

    const renderAmount = (data: WalletTopupsModel) => {
        return (<div>&#8377; {data.Reapmor_data?.TopupAmount?.toFixed(2) || ''}</div>)
    }

    const renderTimeStamp = (data: WalletTopupsModel) => {
        var timeStamp: number = data?.Reapmor_data?.TopupTimestamp ? +data.Reapmor_data.TopupTimestamp : 0;
        var t = moment(timeStamp).format("MMM Do YYYY, h:mm:ss a")
        return (<div>{t || ""}</div>)
    }

    return (
        <DataTable value={tableData} scrollHeight="50vh" scrollable className="rp-table">
            <Column field="Reapmor_data.TopupId" header="Id"></Column>
            <Column field="Reapmor_data.TopupAmount" header="Amount" body={renderAmount}></Column>
            <Column field="Reapmor_data.TopupTimestamp" header="Created Dates" body={renderTimeStamp}></Column>
        </DataTable>
    )
}

const PassbookTable = () => {
    const authData: UserLocalStorage = JSON.parse(localStorage.getItem('auth') || '')
    const { data: passBookData, refetch } = useQuery<PassbookDataModel[], Error>({ queryKey: [ApiMethodKeys.GET_PASSBOOK_DATA, authData.user.userID], queryFn: () => getPassbookApi(authData.user.userID || "") });
    const [tableData, setTableData] = useState<PassbookDataModel[]>()

    useEffect(() => {
        let data = passBookData?.sort((a, b) => +(b.Reapmor_data?.PassbookEntryTimestamp || 0 ) - +(a.Reapmor_data?.PassbookEntryTimestamp || 0))
        setTableData(data);
    }, [passBookData])

    const renderCreditAmount = (data: PassbookDataModel) => {
        if(data.Reapmor_data?.CreditAmount){
            return (<div>&#8377; {data.Reapmor_data?.CreditAmount.toFixed(2) || ''}</div>)
        }
    }

    const renderDebitAmount = (data: PassbookDataModel) => {
        if(data.Reapmor_data?.DebitAmount){
            return (<div>&#8377; {data.Reapmor_data?.DebitAmount.toFixed(2) || ''}</div>)
        }
    }

    const renderWalletBalanceA4 = (data: PassbookDataModel, column: ColumnBodyOptions) => {
        return (<div>&#8377; {data.Reapmor_data?.WalletBalanceAfter?.toFixed(2) || ''}</div>)
    }

    const renderPassBookTimeStamp = (data: PassbookDataModel) => {
        var timeStamp: number = data?.Reapmor_data?.PassbookEntryTimestamp ? +data.Reapmor_data.PassbookEntryTimestamp : 0;
        var t = moment(timeStamp).format("MMM Do YYYY, h:mm:ss a")
        return (<div>{t || ""}</div>)
    }

    return (
        <DataTable value={tableData} scrollHeight="50vh" scrollable className="rp-table">
            <Column field="Reapmor_data.TransactionType" header="Type"></Column>
            <Column field="Reapmor_data.CreditAmount" header="Credit Amount" body={renderCreditAmount}></Column>
            <Column field="Reapmor_data.DebitAmount" header="Debit Amount" body={renderDebitAmount}></Column>
            <Column field="Reapmor_data.WalletBalanceAfter" header="Balance" body={renderWalletBalanceA4}></Column>
            <Column field="Reapmor_data.PassbookEntryTimestamp" header="Date" body={renderPassBookTimeStamp}></Column>
        </DataTable>
    )
}

export default CreditHistory;