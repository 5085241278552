import React from "react";
import "./messagecredit.scss"
import AvailableMessageCredit from "./component/AvailableMessageCredit";
import AddCredit from "./component/AddCredit";
import CreditHistory from "./component/CreditHistory";

const MessageCredit = () => {
    return(
        <>
            <div className="flex gap-3 p-3">
                <AvailableMessageCredit />
                <AddCredit />
            </div>
            <div className="flex px-3">
                <CreditHistory />
            </div>
        </>
    )    
}

export default MessageCredit;