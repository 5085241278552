import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Divider } from "primereact/divider";
import { InputNumber } from "primereact/inputnumber";
import { FC, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { IDropDownItemList } from "..";
import DropdownMenu from "../../../components/Dropdown/Dropdown";
import ErrorMessage from "../../../components/ErrorMessage/ErrorMessage";
import {
  targetAudiencePriceFormValidation,
  targetAudienceGeographyFormValidation,
} from "../../../utils/form-validation";
import "./TargetAudience.scss";
import { IAdCampaignTargetAudience } from "../../../services/models/AdCampaignByIdResponse";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../../providers/AppContext";
import { getAdCampaignChannelDetails } from "../../../services/AdCampaignService";

interface TargetAudienceProps {
  handleBack: () => void;
  handleRouting: () => void;
  currentStep: number;
  items: any;
  handleFormSubmit: any;
  country: IDropDownItemList[];
  states: IDropDownItemList[];
  districts: IDropDownItemList[];
  subDistricts: IDropDownItemList[];
  commodity: IDropDownItemList[];
  channelRate: number;
  defaultValue: IAdCampaignTargetAudience | undefined;
}

const TargetAudience: FC<TargetAudienceProps> = ({
  handleBack,
  handleRouting,
  currentStep,
  handleFormSubmit,
  items,
  country,
  states,
  districts,
  subDistricts,
  commodity,
  channelRate,
  defaultValue,
}) => {
  const appContext = useAppContext();

  const geographyForm = useForm({
    resolver: yupResolver(targetAudienceGeographyFormValidation),
    mode: "onChange",
  });

  const priceForm = useForm({
    resolver: yupResolver(targetAudiencePriceFormValidation),
    mode: "onChange",
  });

  const navigate = useNavigate();
  const Country: any = geographyForm.watch("Country");
  const State: any = geographyForm.watch("State");
  const District: any = geographyForm.watch("District");
  const SubDistrict: any = geographyForm.watch("SubDistrict");
  const TargetFarmers: any = priceForm.watch("TargetFarmers");

  const [showTargetAudience, setTargetAudience] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedSubDistrict, setSelectedSubDistrict] = useState("");
  const [farmersCount, setFarmersCount] = useState({
    whatsapp: 0,
  });

  const [filteredState, setFilteredState] = useState<IDropDownItemList[]>([]);
  const [filteredDistrict, setFilteredDistrict] = useState<IDropDownItemList[]>(
    []
  );
  const [filteredSubDistrict, setFilteredSubDistrict] = useState<
    IDropDownItemList[]
  >([]);
  const [filteredCommodity, setFilteredCommodity] = useState<
    IDropDownItemList[]
  >([]);

  const filterArrayAndFindValue = (
    array: IDropDownItemList[],
    findValue: string
  ): any => {
    return array.find((z) => z.name === findValue);
  };

  useEffect(() => {
    if (selectedCountry !== Country?.name) {
      setSelectedCountry(Country?.name);
      setSelectedState("");
      setSelectedDistrict("");
      setSelectedSubDistrict("");
    }

    if (selectedState !== State?.name) {
      setSelectedState(State?.name);
      setSelectedDistrict("");
      setSelectedSubDistrict("");
    }

    if (selectedDistrict !== District?.name) {
      setSelectedDistrict(District?.name);
      setSelectedSubDistrict("");
    }

    if (selectedSubDistrict !== SubDistrict?.name) {
      setSelectedSubDistrict(SubDistrict?.name);
    }
    setFilteredState(states?.filter((z) => z.foreignKey === Country?.name));
    setFilteredDistrict(districts?.filter((z) => z.foreignKey === State?.name));
    setFilteredSubDistrict(
      subDistricts?.filter((z) => z.foreignKey === District?.name)
    );
    setFilteredCommodity(
      commodity?.filter((z) => z.foreignKey === SubDistrict?.name)
    );
  }, [
    Country,
    State,
    District,
    SubDistrict,
    selectedCountry,
    selectedState,
    selectedDistrict,
    selectedSubDistrict,
    states,
    districts,
    subDistricts,
    commodity,
  ]);

  useEffect(() => {
    if (
      country?.length &&
      defaultValue
    ) {
      geographyForm.setValue(
        "Country",
        filterArrayAndFindValue(
          country,
          defaultValue?.TargetAudience?.Geography?.Country
        )
      );

      geographyForm.setValue(
        "State",
        filterArrayAndFindValue(
          states,
          defaultValue?.TargetAudience?.Geography?.State
        )
      );

      geographyForm.setValue(
        "District",
        filterArrayAndFindValue(
          districts,
          defaultValue?.TargetAudience?.Geography?.District
        )
      );

      geographyForm.setValue(
        "SubDistrict",
        filterArrayAndFindValue(
          subDistricts,
          defaultValue?.TargetAudience?.Geography?.SubDistrict
        )
      );

      geographyForm.setValue(
        "Commodity",
        filterArrayAndFindValue(
          commodity,
          defaultValue?.TargetAudience?.Commodity
        )
      );

      priceForm.setValue(
        "CampaignChannel",
        "whatsapp"
      );

      priceForm.setValue(
        "TargetFarmers",
        defaultValue?.ReachOutTarget?.TargetedFarmers?.toString()
      );

      getAdCampaignChannelDetails({
        country: defaultValue?.TargetAudience?.Geography?.Country,
        state: defaultValue?.TargetAudience?.Geography?.State || "",
        district: defaultValue?.TargetAudience?.Geography?.District || "",
        subdistrict: defaultValue?.TargetAudience?.Geography?.SubDistrict || "",
        commodity: defaultValue?.TargetAudience?.Commodity || "",
      })
        .then((res) => {
          setTargetAudience(true)
          if (res?.data && res?.data.length) {
            setFarmersCount({
              whatsapp: res?.data[0]?.sum_whatsapp,
            });
          }
        })
        .catch((err) => {
          appContext.showToast("Error fetching data", "error");
        });
    }
  }, [
    geographyForm,
    country,
    states,
    districts,
    subDistricts,
    commodity,
    defaultValue,
    priceForm,
    appContext,
  ]);

  const onApply = (data: any): void => {
    getAdCampaignChannelDetails({
      country: data?.Country,
      state: data?.State,
      district: data?.District,
      subdistrict: data?.SubDistrict,
      commodity: data?.Commodity,
    })
      .then((res) => {
        setTargetAudience(true)
        if (res?.data && res?.data.length) {
          setFarmersCount({
            whatsapp: res?.data[0]?.sum_whatsapp,
          });
        }
      })
      .catch((err) => {
        appContext.showToast("Error fetching data", "error");
      });
  };

  const onGeographyFormReset = (): void => {
    geographyForm.reset();
    priceForm.reset();
    setFarmersCount({
      whatsapp: 0
    });
  };

  const onSubmit: SubmitHandler<any> = async () => {
    await geographyForm.trigger();
    await priceForm.trigger();
    if (geographyForm?.formState?.isValid && priceForm?.formState?.isValid) {
      const geographyFormValues: any = geographyForm?.getValues();
      const priceFormValues: any = priceForm?.getValues();

      if (parseInt(priceFormValues?.TargetFarmers) > (farmersCount?.whatsapp || 0)) {
        appContext.showToast(
          "Targeted farmers should be less than available farmers",
          "error"
        );
        return;
      }

      const data = {
        Country: geographyFormValues?.Country?.name,
        State: geographyFormValues?.State?.name,
        District: geographyFormValues?.District?.name,
        SubDistrict: geographyFormValues?.SubDistrict?.name,
        Commodity: geographyFormValues?.Commodity?.name,
        CampaignChannel: priceFormValues?.CampaignChannel,
        TargetFarmers: priceFormValues?.TargetFarmers,
      };

      handleFormSubmit(data);
    } else {
      appContext.showToast("Please fill required fields first", "error");
    }
  };

  return (
    <div className="targetAudiencePage mt-3">
      <form onSubmit={geographyForm.handleSubmit(onApply)}>
        <Card className="targetAudienceCard">
          <span className="flex text-base font-medium txt-clr-black">Audience to be Targeted</span>
          <Divider />
          <div className="txt-clr-black mb-2">Geography</div>
          <div className="flex">
            <span className="controller-card">
              <Controller
                defaultValue={""}
                name="Country"
                control={geographyForm.control}
                render={({ field: { onChange, onBlur, value, name } }) => (
                  <DropdownMenu
                    id={name}
                    options={country}
                    optionLabel="name"
                    placeholder="Select Country"
                    value={value}
                    onChange={onChange}
                    errorText={geographyForm?.formState?.errors?.Country?.message}
                  />
                )}
              />
            </span>
            <div className="controller-card" >
              <Controller
                defaultValue={""}
                name="State"
                control={geographyForm.control}
                render={({ field: { onChange, onBlur, value, name } }) => (
                  <DropdownMenu
                    id={name}
                    options={filteredState}
                    optionLabel="name"
                    placeholder="Select State"
                    value={value}
                    onChange={onChange}
                    errorText={geographyForm?.formState?.errors?.State?.message}
                  />
                )}
              />
            </div>
            <div className="controller-card"><Controller
              defaultValue={""}
              name="District"
              control={geographyForm.control}
              render={({ field: { onChange, onBlur, value, name } }) => (
                <DropdownMenu
                  id={name}
                  options={filteredDistrict}
                  optionLabel="name"
                  placeholder="Select District"
                  value={value}
                  onChange={onChange}
                  errorText={
                    geographyForm?.formState?.errors?.District?.message
                  }
                />
              )}
            /></div>
            <div className="controller-card">
              <Controller
                defaultValue={""}
                name="SubDistrict"
                control={geographyForm.control}
                render={({ field: { onChange, onBlur, value, name } }) => (
                  <DropdownMenu
                    id={name}
                    options={filteredSubDistrict}
                    optionLabel="name"
                    placeholder="Select Sub-District"
                    value={value}
                    onChange={onChange}
                    errorText={
                      geographyForm?.formState?.errors?.SubDistrict?.message
                    }
                  />
                )}
              />
            </div>
          </div>
          <div className="grid mt-2">
            <div className="commodityContainer ">
              <div className="txt-clr-black mb-3">Commodity</div>
              <div className="flex">
                <div className="controller-card">
                  <Controller
                    defaultValue={""}
                    name="Commodity"
                    control={geographyForm.control}
                    rules={{}}
                    render={({ field: { onChange, onBlur, value, name } }) => (
                      <DropdownMenu
                        id={name}
                        options={filteredCommodity}
                        optionLabel="name"
                        placeholder="Select Commodity"
                        value={value}
                        onChange={onChange}
                        errorText={
                          geographyForm?.formState?.errors?.Commodity?.message
                        }
                      />
                    )}
                  />
                </div>
                {showTargetAudience && (
                  <>
                    <Divider layout="vertical" />
                    <div>
                      <div className="txt-clr-black font-medium txt-lg">Available Farmers by Campaign Channel</div>
                      <div className="mt-3 flex">
                        <span className="label-min-width">Whatsapp:</span>
                        <span className="font-medium txt-clr-black">{farmersCount?.whatsapp}</span>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="mt-2">
            <Button
              label="Reset"
              severity="secondary"
              outlined
              className="btn-secondary mr-3"
              onClick={onGeographyFormReset}
            />
            <Button label="Apply" type="submit" className="btn-primary" />
          </div>
        </Card>
      </form>
      <form onSubmit={priceForm.handleSubmit(onSubmit)}>
        <div className="pt-0 mt-4">
          <div className="txt-clr-black mb-3 font-medium txt-lg">Reach out Target</div>
          <div>
            <div className="flex align-items-center mb-3">
              <span className="label-min-width">Campaign Channel:</span>
              <div className="dropdownContainer">
                <Controller
                  defaultValue={"Whatsapp"}
                  name="CampaignChannel"
                  control={priceForm.control}
                  render={({ field: { onChange, onBlur, value, name } }) => (
                    <div>
                      {value}
                    </div>
                  )}
                />
              </div>
            </div>
            <div className="flex mb-3">
              <span className="label-min-width">Rate:</span>
              <span className="txt-clr-black font-medium">INR {channelRate || 0} / Message</span>
            </div>
          </div>
        </div>

        <div className="flex align-items-center mb-2">
          <span className="label-min-width">Targeted Farmers:</span>
          <Controller
            defaultValue={""}
            name="TargetFarmers"
            control={priceForm.control}
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
              <div>
                <InputNumber
                  id={name}
                  inputRef={ref}
                  value={parseFloat(value || "0")}
                  onValueChange={(e) => onChange(e.value)}
                  name={name}
                  className="reach-target"
                  placeholder="300"
                />
                <ErrorMessage
                  errorText={
                    priceForm?.formState?.errors?.TargetFarmers?.message
                  }
                />
              </div>
            )}
          />
        </div>

        <div className="flex align-items-center mb-2">
          <span className="label-min-width" >Price:</span>
          <span className="txt-clr-black font-medium">
            INR {channelRate * (TargetFarmers || 0)}.
          </span>
        </div>

        <div className="mt-4">
          <Button
            label="Close"
            className="btn-secondary mr-3"
            onClick={() => {
              navigate("/dashboard/campaign-list");
            }}
          />
          <Button label="Back" className="btn-secondary mr-3" onClick={handleBack} />
          <Button
            label="Next"
            className="btn-primary"
            type="submit"
            disabled={currentStep === items?.length - 1}
          />
        </div>
      </form>


    </div>
  );
};

export default TargetAudience;
