
export const NetworkConst = {
    GET: "get",
    POST: "post",
    DELETE: "delete",
    PUT: "put"
}

export const ApiMethodKeys = {
    GET_DASHBOARD_SUMMARY: "getDashboardSummmary",
    GET_DASHBOARD_GRAPH_DATA: "getDashboardGraphData",
    GET_WALLET_DATA: "getWalletData",
    GET_PASSBOOK_DATA: "getPassbookData",
    GET_WALLET_TOPUP_DATA: "getWalletTopupData",
}