import * as yup from "yup";

export const campaignSetupValidation = yup.object({
  CampaignTitle: yup.string().required("Campaign Title Is Required"),
  Description: yup.string().required("Description Is Required"),
});

export const targetAudienceGeographyFormValidation = yup.object({
  Country: yup
    .string()
    .transform((value, originalValue) =>
      typeof originalValue === "object" ? originalValue.name : originalValue
    )
    .required("Country Is Required"),
  State: yup
    .string()
    .transform((value, originalValue) =>
      typeof originalValue === "object" ? originalValue.name : originalValue
    ),
  District: yup
    .string()
    .transform((value, originalValue) =>
      typeof originalValue === "object" ? originalValue.name : originalValue
    ),
  SubDistrict: yup
    .string()
    .transform((value, originalValue) =>
      typeof originalValue === "object" ? originalValue.name : originalValue
    ),
  Commodity: yup
    .string()
    .transform((value, originalValue) =>
      typeof originalValue === "object" ? originalValue.name : originalValue
    ),
});

export const targetAudiencePriceFormValidation = yup.object({
  CampaignChannel: yup
    .string()
    .default("whatsapp")
    .required("Campaign Channel Is Required"),
  TargetFarmers: yup.string().required("Target Farmers Is Required"),
});

export const contentValidation = yup.object({
  Text: yup.string().required("Campaign Name Is Required"),
  Media: yup
    .string()
    .required("Campaign Document Is Required. It must be a image"),
});

export const editModeContentValidation = yup.object({
  Text: yup.string().required("Campaign Name Is Required"),
  Media: yup.string(),
});
