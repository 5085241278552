import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IAdCampaignDetail } from "../../../services/models/AdCampaignByIdResponse";
import "./Summary.scss";
import UrlConstants from "../../../constants/ApiUrls";
import { Card } from "primereact/card";
import { Divider } from "primereact/divider";

interface SummaryProps {
  currentStep: number;
  items: any;
  handleSubmitAdCampaign: () => void;
  handleBack: () => void;
  adCampaign: IAdCampaignDetail | null;
}

const Summary: FC<SummaryProps> = ({
  currentStep,
  items,
  handleSubmitAdCampaign,
  handleBack,
  adCampaign,
}) => {
  const navigate = useNavigate();

  const columns = [
    { codeColumn: "row", nameColumn: "Code" },
    { codeColumn: "data", nameColumn: "Name" },
  ];
  const [summaryData, setSummaryData] = useState<any[]>([]);

  useEffect(() => {
    if (adCampaign) {
      setSummaryData([
        {
          id: 1,
          row: "Title:",
          data: adCampaign.AdCampaignBasicDetail?.AdCampaignTitle,
          color: "black",
        },
        {
          id: 2,
          row: "Description:",
          data: adCampaign.AdCampaignBasicDetail?.AdCampaignDescription,
          color: "black",
        },
        {
          id: 3,
          row: "Target - Geography:",
          data: `${adCampaign.AdCampaignTargetAudience?.TargetAudience?.Geography?.Country} - ${adCampaign.AdCampaignTargetAudience?.TargetAudience?.Geography?.State} - ${adCampaign.AdCampaignTargetAudience?.TargetAudience?.Geography?.District} - ${adCampaign.AdCampaignTargetAudience?.TargetAudience?.Geography?.SubDistrict}`,
          color: "black",
        },
        {
          id: 4,
          row: "Target - Commodity:",
          data: adCampaign.AdCampaignTargetAudience?.TargetAudience?.Commodity,
          color: "black",
        },
        {
          id: 5,
          row: "Content - Text:",
          data: adCampaign.AdCampaignContent?.ContentText,
          color: "black",
        },
        {
          id: 6,
          row: "Content - Media:",
          data: adCampaign.AdCampaignContent?.ContentMediaName,
          hrefValue: adCampaign.AdCampaignContent?.ContentMedia,
          color: "blue",
        },
        {
          id: 7,
          row: "Campaign Channel:",
          data: adCampaign.AdCampaignTargetAudience?.ReachOutTarget
            ?.CampaignChannel,
          color: "black",
        },
        {
          id: 8,
          row: "Target Farmers:",
          data: adCampaign.AdCampaignTargetAudience?.ReachOutTarget
            ?.TargetedFarmers,
          color: "black",
        },
        {
          id: 9,
          row: "Price:",
          data: `INR ${adCampaign.AdCampaignTargetAudience?.ReachOutTarget?.Price}`,
          color: "black",
        },
      ]);
    }
  }, [adCampaign]);

  return (
    <>
      <Card className="summaryLayout mt-3">
        <div className="txt-clr-black font-medium txt-lg">Summary of the Ad Campaign</div>
        <Divider />
        {summaryData.map((data) =>
          <div className="flex w-full align-items-center">
            <div className="w-3">
              <p className="my-2">{data.row}</p>
            </div>
            <div className="w-full">
              {(data?.row === "Content - Media:") ?
                (<a href={`${data?.hrefValue}`}
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    color: data?.color,
                  }}
                >
                  {data.data}
                </a>
                ) : (<div className="font-semibold txt-clr-black">{data.data}</div>)}
            </div>
          </div>
        )}
      </Card>
      <div className="mt-4">
        <Button
          label="Close"
          className="btn-secondary mr-3"
          onClick={() => {
            navigate("/dashboard/campaign-list");
          }}
        />
        <Button label="Back" className="btn-secondary mr-3" onClick={handleBack} />
        <Button
          label="Submit"
          className="btn-primary mr-3"
          onClick={handleSubmitAdCampaign}
          disabled={currentStep === items?.length - 1}
        />
      </div>
    </>
  );
};

export default Summary;
