import { Card } from "primereact/card"
import React from "react"

interface DashboardCardProps{
    numValue: string,
    cardTitle: string,
    cssClass?: string,
    cb?: () => void
}

const DashboardTile = (props: DashboardCardProps) => {
    return(
        <Card className={`flex align-content-center justify-content-center flex-1 ${props.cssClass}`} onClick={ e =>
            props.cb ? props.cb(): ''
        }>
            <p className="text-3xl font-semibold text-center txt-clr-blue">{props.numValue}</p>
            <p className="text-l font-normal text-center">{props.cardTitle}</p>
        </Card>
    )
}

export default DashboardTile