import { MenuItem } from "primereact/menuitem";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import { useAppContext } from "../../providers/AppContext";
import { getPreSignedUrl, photoUpload } from "../../services/AWSS3Storage";
import {
  createAdCampaign,
  createAdCampaignContent,
  createAdCampaignTargetAudience,
  getAdCampaignById,
  getChanelRate,
  getGeographyAndCommodityList,
  submitAdCampaign,
  updateAdCampaign,
} from "../../services/AdCampaignService";
import { IAdCampaignDetail } from "../../services/models/AdCampaignByIdResponse";
import { ICreateTargetAudienceRequestModel } from "../../services/models/createTargetAudienceRequestModel";
import "../CreateNewCampaign/index.scss";
import CampaignsSetup from "./CampaignSetup/CampaignsSetup";
import Content from "./Content/Content";
import Summary from "./Summary/Summary";
import TargetAudience from "./TargetAudience/TargetAudience";
import { getFileExtension } from "../../constants/util";
import UrlConstants from "../../constants/ApiUrls";

export interface IAdCampaignBasicDetail {
  AdCampaignID: string;
  AdUIID: number;
  AdCampaignTitle: string;
  AdCampaignDescription: string;
}

export class IDropDownItemList {
  foreignKey: string = "";
  name: string = "";
}

const CreateNewCampaign = () => {
  const appContext = useAppContext();
  const routeParams = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [currentStep, setCurrentStep] = useState(
    searchParams.get("status") === "submitted" ? 4 : 0
  );

  const [basicDetail, setBasicDetail] = useState<IAdCampaignDetail | null>(
    null
  );

  const [adCampaignId, setAdCampaignId] = useState(
    routeParams?.id?.toLowerCase() === "create" ? "" : routeParams?.id
  );

  const [channelRate, setChannelRate] = useState(0);

  const [completedSteps, setCompletedSteps] = useState(
    currentStep === 0 ? [0] : [0, 1, 2, 3]
  );

  const [allCountry, setAllCountry] = useState<IDropDownItemList[]>([]);
  const [allState, setAllState] = useState<IDropDownItemList[]>([]);
  const [allDistrict, setAllDistrict] = useState<IDropDownItemList[]>([]);
  const [allSubDistrict, setAllSubDistrict] = useState<IDropDownItemList[]>([]);
  const [allCommodity, setAllCommodity] = useState<IDropDownItemList[]>([]);

  const getAdCampaign = (adCampaignId: string) => {
    getAdCampaignById(adCampaignId)
      .then((res) => {
        if (res.status === 200) {
          if (
            res.data &&
            res.data?.AdCampaignContent &&
            res.data?.AdCampaignContent?.ContentMedia
          ) {
            res.data.AdCampaignContent.ContentMedia = `${process.env.REACT_APP_S3_BUCKET_URL}${res.data.AdCampaignContent.ContentMedia}`;
          }

          setBasicDetail(res.data);
        }
      })
      .catch((res) => {
        appContext.showToast("Error in getting AdCampaign Details", "error");
      });
  };

  useEffect(() => {
    if (adCampaignId) {
      getAdCampaign(adCampaignId);
      let InternalCountry: IDropDownItemList[] = [];
      let InternalState: IDropDownItemList[] = [];
      let InternalDistrict: IDropDownItemList[] = [];
      let InternalSubDistrict: IDropDownItemList[] = [];
      let InternalCommodity: IDropDownItemList[] = [];

      getGeographyAndCommodityList()
        .then((res) => {
          if (res?.status === 200) {
            //countries
            const allCountry = Object.keys(res?.data);
            InternalCountry = allCountry?.map((z) => {
              return { foreignKey: "", name: z };
            });

            //states
            allCountry.forEach((countries) => {
              let states: IDropDownItemList[] = [];
              states = Object.keys(res?.data[countries]).map((state) => {
                return { foreignKey: countries, name: state };
              });
              InternalState = [...InternalState, ...states];

              //district
              states
                .map((q) => q?.name)
                .forEach((state) => {
                  let districts: IDropDownItemList[] = [];
                  districts = Object.keys(res?.data[countries][state])?.map(
                    (district) => {
                      return { foreignKey: state, name: district };
                    }
                  );
                  InternalDistrict = [...InternalDistrict, ...districts];

                  //set sub district
                  districts
                    .map((q) => q?.name)
                    .forEach((district) => {
                      let subdistricts: IDropDownItemList[] = [];
                      subdistricts = Object.keys(
                        res?.data[countries][state][district]
                      )?.map((subdistrict) => {
                        return { foreignKey: district, name: subdistrict };
                      });
                      InternalSubDistrict = [
                        ...InternalSubDistrict,
                        ...subdistricts,
                      ];

                      //set commodities
                      subdistricts
                        ?.map((q) => q?.name)
                        .forEach((subdistrict) => {
                          let commodities: IDropDownItemList[] = [];
                          commodities = res?.data[countries][state][district][
                            subdistrict
                          ]?.map((commodity: string) => {
                            return { foreignKey: subdistrict, name: commodity };
                          });
                          InternalCommodity = [
                            ...InternalCommodity,
                            ...commodities,
                          ];
                        });
                    });
                });
            });
            setAllCountry(InternalCountry);
            setAllState(InternalState);
            setAllDistrict(InternalDistrict);
            setAllSubDistrict(InternalSubDistrict);
            setAllCommodity(InternalCommodity);
          }
        })
        .catch((err) => {
          console.log(err);
        });

      getChanelRate()
        .then((res) => {
          setChannelRate(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [adCampaignId, appContext]);

  useEffect(() => {
    if (basicDetail) {
      const completedSteps = [0];
      if (basicDetail?.AdCampaignBasicDetail?.AdCampaignID) {
        completedSteps.push(1);
      }
      if (basicDetail?.AdCampaignTargetAudience?.TargetAudience?.Commodity) {
        completedSteps.push(2);
      }
      if (basicDetail?.AdCampaignContent?.ContentText) {
        completedSteps.push(3);
      }
      setCompletedSteps(completedSteps);
    }
  }, [basicDetail]);

  const items: MenuItem[] = [
    {
      label: "Campaign Setup",
    },
    {
      label: "Target Audience",
    },

    {
      label: "Content",
    },

    {
      label: "Summary",
    },

    {
      label: "Checkout",
    },
  ];

  const handleRouting = () => {
    if (currentStep < items?.length - 1) {
      if (adCampaignId) {
        getAdCampaign(adCampaignId);
      }
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep((prevStep) => prevStep - 1);
    }
  };

  const handleSubmit = (data: any) => {
    if (!adCampaignId) {
      appContext.showLoader();
      createAdCampaign({
        adCampaignTitle: data?.CampaignTitle,
        adCampaignDescription: data?.Description,
      })
        .then((res) => {
          if (res?.status === 200) {
            setBasicDetail(res?.data?.data?.AdCampaignBasicDetail);
            setAdCampaignId(
              res?.data?.data?.AdCampaignBasicDetail?.AdCampaignID
            );
            handleRouting();
            appContext.showToast("AdCampaign Successfully created.");
          }
        })
        .catch((err) => {
          console.log(err);
          appContext.showToast(
            "Error occured on creating AdCampaign.",
            "error"
          );
        })
        .finally(() => {
          appContext.hideLoader();
        });
    } else {
      appContext.showLoader();
      updateAdCampaign({
        adCampaignId: adCampaignId,
        adCampaignTitle: data?.CampaignTitle,
        adCampaignDescription: data?.Description,
      })
        .then((res) => {
          if (res?.status === 200) {
            handleRouting();
            setCompletedSteps([...completedSteps, 1]);
            appContext.showToast("AdCampaign Successfully updated.");
          }
        })
        .catch((err) => {
          console.log(err);
          appContext.showToast(
            "Error occured on updating AdCampaign.",
            "error"
          );
        })
        .finally(() => {
          appContext.hideLoader();
        });
    }
  };

  const stepIndex = (index: number) => {
    setCurrentStep(index);
  };

  const handleTargetAudienceSubmit = (data: any) => {
    appContext.showLoader();
    if (adCampaignId) {
      const request: ICreateTargetAudienceRequestModel = {
        adCampaignId: adCampaignId,
        campaignChannel: data?.CampaignChannel,
        commodity: data?.Commodity,
        availableFarmerCount: 100,
        geography: {
          country: data?.Country,
          district: data?.District,
          state: data?.State,
          subDistrict: data?.SubDistrict,
        },
        reachOutTarget: {
          targetedFarmers: Number(data?.TargetFarmers),
          ratePerUnit: channelRate,
          price: Number(data?.TargetFarmers ?? 1) * channelRate,
        },
      };

      createAdCampaignTargetAudience(request)
        .then((res) => {
          if (res?.status === 200) {
            handleRouting();
            setCompletedSteps([...completedSteps, 2]);
            appContext.showToast(
              "AdCampaign target audience successfully created."
            );
          }
        })
        .catch((err) => {
          console.log(err);
          appContext.showToast(
            "Error occured on creating AdCampaign target audience.",
            "error"
          );
        })
        .finally(() => {
          appContext.hideLoader();
        });
    }
  };

  const handleSubmitFile = async (data: any) => {
    try {
      if (adCampaignId) {
        appContext.showLoader();

        let identifier = "";
        if (basicDetail) {
          if (
            basicDetail?.AdCampaignContent?.ContentMediaName !== data.fileName
          ) {
            const fileObject = await fetch(data?.Media);

            const extension = getFileExtension(data.fileName);
            identifier = `${adCampaignId}.${extension}`;
            const resPreSignedUrl: any = await getPreSignedUrl({
              productType: "VALAR",
              photoList: identifier,
            });

            const uploadUrl = resPreSignedUrl[0][identifier];
            await photoUpload(uploadUrl, await fileObject?.blob());
          } else {
            identifier = basicDetail.AdCampaignContent.ContentMedia.replaceAll(
              process.env.REACT_APP_S3_BUCKET_URL || '',
              ""
            );
          }
        }

        createAdCampaignContent({
          adCampaignId: adCampaignId?.toString(),
          contentText: data?.Text,
          contentMediaURL: identifier,
          contentMediaName: data.fileName,
        })
          .then((res) => {
            setCompletedSteps([...completedSteps, 3]);
            appContext.showToast("AdCampaign Content Successfully created");
            handleRouting();
          })
          .catch((err) => {
            appContext.showToast(
              "Error in AdCampaign Content Creation",
              "error"
            );
          })
          .finally(() => {
            appContext.hideLoader();
          });
      }
    } catch (err) {
      appContext.hideLoader();
    }
  };

  const handleSubmitAdCampaign = () => {
    if (adCampaignId) {
      appContext.showLoader();
      submitAdCampaign(adCampaignId)
        .then((res) => {
          appContext.showToast("AdCampaign Reviewed successfully", "success");
          // navigate to list page
          navigate("/dashboard/campaign-list");
        })
        .catch((res) => {
          appContext.showToast("Error in AdCampaign Reviewed", "error");
        })
        .finally(() => {
          appContext.hideLoader();
        });
    }
  };

  const renderStepComponent = () => {
    try {
      switch (items[currentStep]?.label) {
        case "Campaign Setup":
          return (
            <CampaignsSetup
              currentStep={currentStep}
              items={items}
              basicDetail={basicDetail?.AdCampaignBasicDetail}
              handleRouting={handleRouting}
              handleFormSubmit={handleSubmit}
            />
          );
        case "Target Audience":
          return (
            <TargetAudience
              defaultValue={basicDetail?.AdCampaignTargetAudience}
              channelRate={channelRate}
              currentStep={currentStep}
              items={items}
              handleRouting={handleRouting}
              handleBack={handleBack}
              handleFormSubmit={handleTargetAudienceSubmit}
              country={allCountry}
              states={allState}
              districts={allDistrict}
              subDistricts={allSubDistrict}
              commodity={allCommodity}
            />
          );
        case "Content":
          return (
            <Content
              existingValue={basicDetail?.AdCampaignContent}
              currentStep={currentStep}
              items={items}
              handleRouting={handleRouting}
              handleBack={handleBack}
              handleFormSubmit={handleSubmitFile}
            />
          );
        case "Summary":
          return (
            <Summary
              adCampaign={basicDetail}
              currentStep={currentStep}
              items={items}
              handleSubmitAdCampaign={handleSubmitAdCampaign}
              handleBack={handleBack}
            />
          );
        // case "Checkout":
        //   // return <Checkout adCampaign={basicDetail} />;
        default:
          return null;
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <span className="flex text-4xl font-semibold mb-3">Ad Campaigns - Create New Campaign</span>
      <BreadCrumbs
        completedSteps={completedSteps}
        setStep={stepIndex}
        currentStep={currentStep}
        items={items}
      ></BreadCrumbs>

      {renderStepComponent()}
    </div>
  );
};

export default CreateNewCampaign;
