import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Message } from "primereact/message";
import { FC, useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { IAdCampaignBasicDetail } from "..";
import { campaignSetupValidation } from "../../../utils/form-validation";
import "./CampaignsSetup.scss";
import { Card } from "primereact/card";
import { Divider } from "primereact/divider";

interface CampaignSetupProps {
  currentStep: number;
  items: any;
  handleRouting: () => void;
  basicDetail: IAdCampaignBasicDetail | undefined;
  handleFormSubmit: any;
}
const CampaignsSetup: FC<CampaignSetupProps> = ({
  currentStep,
  items,
  handleRouting,
  handleFormSubmit,
  basicDetail,
}) => {
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(campaignSetupValidation),
  });

  useEffect(() => {
    if (basicDetail) {
      setValue("CampaignTitle", basicDetail?.AdCampaignTitle);
      setValue("Description", basicDetail?.AdCampaignDescription);
    }
  }, [basicDetail, setValue]);

  const onSubmit: SubmitHandler<any> = (data) => {
    handleFormSubmit(data);
  };
  const navigate = useNavigate();

  return (
    <div className="campaignSetupLayout mt-3">
      <form className="campaignSetupLayout.form" onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <span className="flex font-medium txt-clr-black">Setup your Campaign</span>
          <Divider />
          <label htmlFor="CampaignTitle" className="txt-clr-black">Campaign Title</label>
          <Controller
            defaultValue={""}
            name="CampaignTitle"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <InputText
                id="CampaignTitle"
                aria-describedby="username-help"
                className="mt-3"
                value={value}
                onChange={onChange}
              />
            )}
          />
          {errors?.CampaignTitle && (
            <Message
              severity="error"
              text={errors?.CampaignTitle?.message}
              className="error-msg my-2"
            />
          )}
          <div className="m-4"></div>
          <label htmlFor="Description" className="txt-clr-black">Description</label>
          <Controller
            defaultValue={""}
            name="Description"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <InputTextarea
                id="Description"
                rows={5}
                onBlur={onBlur}
                cols={30}
                value={value}
                onChange={onChange}
                className="mt-3"
              />
            )}
          />
          {errors?.Description && (
            <Message
              severity="error"
              text={errors?.Description?.message}
              className="error-msg mt-2"
            />
          )}
        </Card>
        <div className="mt-4">
          <Button
            label="Cancel"
            className="btn-secondary mr-3"
            outlined
            onClick={() => navigate("/dashboard/campaign-list")}
          />
          <Button
            type="submit"
            label="Save as Draft"
            className="btn-secondary mr-3"
            outlined
          />
          <Button
            type="submit"
            label="Next"
            className="btn-primary"
            disabled={currentStep === items?.length - 1}
          />
        </div>
      </form>
    </div >
  );
};

export default CampaignsSetup;
