import UrlConstants from "../constants/ApiUrls";
import axios from "./ApiService";

export const login = (username: string, password: string) => {
  return axios({
    url: UrlConstants.USER.LOGIN,
    method: "post",
    data: {
      userID: username,
      password: password,
      application: "VALAR",
    },
  });
};
