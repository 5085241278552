import DashboardEndPoints from "./endpoint";
import axios from "../ApiService";
import { NetworkConst } from "../NetworkConst";
import WalletEndPoints from "./endpoint";
import { WalletModel } from "../models/wallet/wallet";
import { PassbookDataModel } from "../models/wallet/passbook";
import { WalletTopupsModel } from "../models/wallet/wallettopup";

export const getWalletApi = async (userID: string)  => {
    var res = await axios<WalletModel[]>({
        url: WalletEndPoints.wallet,
        params: { userID },
        method: NetworkConst.GET,
        withCredentials: true,
    });
    return res.data
};

export const getPassbookApi = async (userID: string) => {
    var res = await axios<PassbookDataModel[]>({
        url: WalletEndPoints.passbookEnteries,
        params: { userID },
        method: NetworkConst.GET,
        withCredentials: true,
    });

    return res.data
};

export const getWalletTopupApi = async (userID: string) => {
    var res = await axios<WalletTopupsModel[]>({
        url: WalletEndPoints.walletTopups,
        params: { userID },
        method: NetworkConst.GET,
        withCredentials: true,
    });

    return res.data
};