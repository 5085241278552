// Login.tsx
import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import { login } from "../../services/AuthService";
import { useAppContext } from "../../providers/AppContext";
import "./Login.scss";
import Sildeshow from "./Sildeshow";

const Login: React.FC = () => {
  const appContext = useAppContext();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = () => {
    appContext.showLoader(); // Show loader when login process starts
    login(username, password)
      .then((res) => {
        if (res.status === 200) {
          localStorage.setItem("auth", JSON.stringify(res?.data)); // Store auth data in local storage
          appContext.setLoggedInFlag(true); // Set logged in flag in context
          appContext.showToast("You are successfully logged in."); // Show success toast
        }
      })
      .catch((err) => {
        appContext.showToast("Login failed", "error"); // Show error toast if login fails
      })
      .finally(() => {
        appContext.hideLoader(); // Hide loader after login process completes
      });
  };


  return (
    <div className="login-container">
      <div className="left-side" style={{ padding: "50px" }}>
        <img src="/image/image.png" alt="Logo page" />
        <h2>Sign in</h2>
        <h3>to access valar business suite</h3>

        <form  onSubmit={(e) => e.preventDefault()}>
          <div className="p-field">
            <span className="p-float-label">
              <InputText  className="text-box" style={{ width: '130%'}}
                id="signup-username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <label htmlFor="signup-username">Username</label>
            </span>
          </div>

          <div className="p-field p-field-password" style={{ marginTop: '1.8rem'}}>
            <span className="p-float-label">
              <InputText className="text-box" style={{ width: '130%'}}
                id="signup-password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <label htmlFor="signup-password">Password</label>
            </span>
          </div>

          <Button style={{ width: '130%',marginTop: '0.8rem'}}
            className="text-box p-field-password"
            label="Sign In"
            onClick={handleLogin}
          />
        </form>
      </div>
      <hr/>
      <div className="right-side" >
        <Sildeshow/>
      </div>
    </div>
  );
};

export default Login;
