/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from "react";
import "./Header.scss";
import "primeicons/primeicons.css";
import Userpic from "../../assets/Profile.jpeg";
import Logo from "../../assets/image.png";
import { useAppContext } from "../../providers/AppContext";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { ApiMethodKeys } from "../../services/NetworkConst";
import { WalletModel } from "../../services/models/wallet/wallet";
import { getWalletApi } from "../../services/wallet/wallet";
import { UserLocalStorage } from "../../services/models/user/user";
import { useWalletContext } from "../../providers/WalletContext";
import packageInfo from "../../../package.json"

const Header: FC<{}> = () => {
  const appContext = useAppContext();
  const { walletState, setWalletState } = useWalletContext();
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);
  const authData: UserLocalStorage = JSON.parse(localStorage.getItem('auth') || '')
  const { data: walletData, refetch } = useQuery<WalletModel[], Error>({ queryKey: [ApiMethodKeys.GET_WALLET_DATA, authData.user.userID], queryFn: () => getWalletApi(authData.user.userID || "") });

  const signOut = () => {
    localStorage.removeItem("auth");
    appContext?.setLoggedInFlag(false);
    navigate("/")
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  useEffect(() => {
    refetch()
  })

  useEffect(() => {
    setWalletState(walletData || [])
  }, [walletData])

  return (
    <header className="headerLayout">
      <span className="layoutTopbarLogo">
        <img src={Logo} alt="Reampor" className="logoimage cursor-p" onClick={ () => navigate('/dashboard/dashboard')} />
      </span>
      <div className="flex align-items-center">
        <div className="border-round wallet" onClick={e => { navigate("/dashboard/message-credit") }}>
          <p className="text-l m-0 txt-clr-black font-normal cursor-p">Wallet Balance: <span className="txt-clr-blue font-semibold">{walletState.length > 0 ? walletState[0]?.Reapmor_data?.ValarWalletBalance?.toFixed(2) : 0} &#8377;</span></p>
        </div>
        <div className="headerIcons">

          <div className="userProfileWrapper" onClick={toggleDropdown}>
            <img src={Userpic} alt="img" className="userProfile" />
            {showDropdown && (
              <div className="dropdownContent">

                <a href="#" onClick={signOut}>Sign Out</a>
                <p className="version my-1 txt-clr-black ">Version {packageInfo.version}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
