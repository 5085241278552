import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import "./AppContext.scss";

// create context
const AppContext = createContext({
  showToast: (message: string, type: "success" | "error" = "success") => {},
  showLoader: () => {},
  hideLoader: () => {},
  setLoggedInFlag: (value: boolean) => {},
  isLoggedIn: false,
});

export const AppContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const toastRef = useRef<Toast>(null);
  const loader = useRef<HTMLDivElement>(null);
  const showToast = (
    message: string,
    type: "success" | "error" = "success"
  ) => {
    if (!toastRef.current) return;
    toastRef.current.show({
      severity: type,
      summary: "Success",
      detail: message,
      life: 2000,
      className: "toast-section",
    });
  };

  useEffect(() => {
    if (localStorage.getItem("auth")) {
      setIsLoggedIn(true);
    }
  }, [setIsLoggedIn]);

  const showLoader = () => {
    if (loader && loader.current) {
      loader.current.className = "";
    }
  };

  const hideLoader = () => {
    if (loader && loader.current) {
      loader.current.className = "hideLoader";
    }
  };

  const setLoggedInFlag = (input: boolean) => {
    setIsLoggedIn(input);
  };

  return (
    <AppContext.Provider
      value={{ showToast, showLoader, hideLoader, isLoggedIn, setLoggedInFlag }}
    >
      <div
        className="hideLoader"
        ref={loader}
        style={{
          zIndex: 99999999999,
          position: "absolute",
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "#0000005c",
        }}
      >
        <ProgressSpinner />
      </div>
      <Toast ref={toastRef} />
    <div>{children}</div>
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error(
      "AppContext have to be used within ToastContextProvider"
    );
  }

  return context;
};
