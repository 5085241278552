import { Card } from "primereact/card";
import React from "react";
import { useWalletContext } from "../../../providers/WalletContext";

const AvailableMessageCredit = () => {
    const { walletState } = useWalletContext()

    return(
        <>
            <Card className="flex flex-1 align-content-center">
                <p className="text-l font-normal my-0">Avaliable Balance</p>
                <p className="text-3xl font-semibold txt-clr-blue my-2">&#8377; {walletState.length > 0 ? walletState[0]?.Reapmor_data?.ValarWalletBalance?.toFixed(2) : 0}</p>
            </Card>
        </>
    )
}

export default AvailableMessageCredit;