import axios from "axios";
import React from "react";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 3000,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  async (req) => {
    req.headers.set("application", "VALAR");

    const authData = localStorage.getItem("auth");
    if (authData) {
      const jsonState = JSON.parse(authData);
      if (jsonState) {
        if (jsonState.token) {
          req.headers.Authorization = `Bearer ${jsonState.token}`;
        }

        if (jsonState.user) {
          req.headers.set("id", jsonState.user.userID);
        }
      }
    }
    return req;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // Toast.show({
    //   type: "error",
    //   text1: "App",
    //   text2: "Something Went Wrong!",
    //   position: "bottom",
    // });
    return Promise.reject(error);
  }
);

export default axiosInstance;



// export const getQuery = (uniqueId: QueryKey, fn: QueryFunction, options: UseQueryOptions = {
//   retry: false,
//   staleTime: Infinity
// }) => {
//   return useQuery(uniqueId, fn, options)
// }