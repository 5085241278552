const UrlConstants = {
  USER: {
    LOGIN: "login",
  },
  ADCAMPAIGN: {
    LIST: "adcampaign/getAdCampaignList?userID=:userId",
    CREATE: "adcampaign/create",
    UPDATE: "adcampaign/update",
    REVIEW: "adcampaign/review",
    SUBMIT: "adcampaign/submit",
    EXECUTE: "adcampaign/execute",
    CREATE_TARGET_AUDIENCE: "adcampaign/targetAudience/create",
    CREATE_CONTENT: "adcampaign/content/create",
    GET_BY_ID: "adcampaign/getDetail?adCampaignId=:adCampaignId",
    GET_GEOGRAPHY_AND_COMMODITY_LIST: "adcampaign/getGeographyAndCommodityList",
    GET_ADD_CAMPAIGN_CHANNEL_RATE: "adcampaign/getCampaignChannelRate",
    GET_AD_CAMPAIGN_CHANNEL_DETAILS:
      "adcampaign/getCampaignChannelDetails?country=:country&state=:state&district=:district&subdistrict=:subdistrict&commodity=:commodity",
  },
  UPLOAD_FILE: {
    GET_PRESIGNED_URL: "/uploads?",
  }
};

export default UrlConstants;
