import { useEffect, useState } from "react";
import "./dashboard.scss";
import DashboardHeader from "./component/header";
import DashboardTile from "./component/dashboardtile";
import { useNavigate } from "react-router-dom";
import DashboardChart, { DashboardChartProps } from "./component/dashboardchart";
import { getGraphDataApi, getSummaryApi } from "../../services/dashboard/dashboard";
import { ApiMethodKeys } from "../../services/NetworkConst";
import DashBoardSummaryModel from "../../services/models/dashboard/dashboardsummary";
import { DashboardGraphDataModel } from "../../services/models/dashboard/dashboardgraphdata";
import { useQuery } from "@tanstack/react-query";
import moment from 'moment';
import { UserLocalStorage } from "../../services/models/user/user";
import { useAppContext } from "../../providers/AppContext";

const Dashboard = () => {
    const navigate = useNavigate();
    const appCtx = useAppContext();
    const authData: UserLocalStorage = JSON.parse(localStorage.getItem('auth') || '')
    const [monthInterval, setMonthInterval] = useState("Current Month");
    const [curSummaryData, setCurSummaryData] = useState<DashBoardSummaryModel>();
    const [chartData, setChartData] = useState<DashboardChartProps>();
    const { isLoading: summaryLoading, data: summaryData, error: summaryErr, refetch: refetchSummary } = useQuery<DashBoardSummaryModel[], Error>({ queryKey: [ApiMethodKeys.GET_DASHBOARD_SUMMARY, authData.user.userID], queryFn: () => getSummaryApi(authData.user.userID || "") })
    const { isLoading: graphLoading, data: graphData,  error: graphErr, refetch: refetchgraph } = useQuery<DashboardGraphDataModel[], Error>({ queryKey: [ApiMethodKeys.GET_DASHBOARD_GRAPH_DATA, authData.user.userID], queryFn: () => getGraphDataApi(authData.user.userID || "") });

    useEffect(() => {
        let monthkey = monthInterval == "Current Month" ? `${moment().format("MMM")}-${moment().format("YYYY")}` : `${moment().subtract(1, 'months').format("MMM")}-${moment().format("YYYY")}`;
        setCurSummaryData(summaryData?.find(data => data.month_year == monthkey))

        let tempArray = Array<number>(moment(monthkey, "MMM-YYYY").daysInMonth()).fill(0)
        var curChartData: DashboardChartProps = {
            msgSent: [...tempArray],
            msgDelivered: [...tempArray],
            msgRead: [...tempArray],
            dates: Array.from(tempArray.keys(), n => n + 1)
        }
        graphData?.filter(data => data.month_year == monthkey).forEach(data => {
            let idx = moment(data?.date_transaction).date()
            curChartData.msgDelivered[idx] = data.total_msg_delivered || 0
            curChartData.msgRead[idx] = data.total_msg_read || 0
            curChartData.msgSent[idx] = data.total_msg_sent || 0
        })

        setChartData(curChartData)
        
    }, [monthInterval, summaryData, graphData])

    useEffect( () => {
        if(!(graphLoading && summaryLoading)){
            appCtx.hideLoader()
        }else{
            appCtx.showLoader()
        }
    }, [summaryLoading, graphLoading])

    return (
        <>
            <DashboardHeader cur={monthInterval} cb={e => setMonthInterval(e)} />
            <div className="gap-3 flex flex-wrap justify-content-between pb-3">
                <DashboardTile numValue={curSummaryData?.total_campaign?.toString() || "0"} cardTitle="Ad Campaings" cssClass="cursor-p" cb={() => {
                    navigate('/dashboard/campaign-list')
                }} />
                <DashboardTile numValue={curSummaryData?.total_msg_sent?.toString() || "0"} cardTitle="Messages Sent" />
                <DashboardTile numValue={curSummaryData?.total_msg_delivered?.toString() || "0"} cardTitle="Messages Delivered" />
                <DashboardTile numValue={curSummaryData?.total_msg_read?.toString() || "0"} cardTitle="Messages Read" />
                <DashboardTile numValue={curSummaryData?.total_amt_spent?.toString() || "0"} cardTitle="Amount Spent (&#8377;)" />
            </div>
            <DashboardChart msgSent={chartData?.msgSent || []} msgRead={chartData?.msgRead || []} msgDelivered={chartData?.msgDelivered || []} dates={chartData?.dates || []} />
        </>
    )

}

export default Dashboard