import React, { createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useState } from "react";
import { WalletModel } from "../services/models/wallet/wallet";

type WalletCtxProps = {
    walletState: WalletModel[];
    setWalletState: Dispatch<SetStateAction<WalletModel[]>>;
}

export const WalletCtx = createContext<WalletCtxProps>({} as WalletCtxProps)

export const WalletContextProvider = ({ children }: PropsWithChildren<{}>) => {
    const [walletState, setWalletState] = useState<WalletModel[]>([])

    return (
        <WalletCtx.Provider value={{ walletState, setWalletState }}>
            {children}
        </WalletCtx.Provider>
    )
}

export const useWalletContext = () => useContext(WalletCtx)