import { Dropdown } from "primereact/dropdown";
import React, { useState } from "react";

interface DashboardHeaderProps {
    cur?: string,
    cb: (data: string) => void
}

const DashboardHeader = (props: DashboardHeaderProps) => {
    const intervalList = ["Current Month","Prev Month"];
    return (
        <>
            <div className="flex justify-content-between pb-3">
                <span className="flex text-4xl font-semibold">Dashboard</span>
                <Dropdown options={intervalList} className="rm-select" value={props.cur} onChange={(e) => props.cb(e.value)}/>
            </div>
        </>
    )

}

export default DashboardHeader