import "./App.scss";
import Login from "./Pages/Login/Login";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import packageInfo from "../package.json"
import Layout from "./Pages/Layout";
import { useAppContext } from "./providers/AppContext";
import CacheBuster from "react-cache-buster";

function App() {
  const apContext = useAppContext();

  return (
    <CacheBuster
      currentVersion={packageInfo.version}
      isEnabled={true}
      isVerboseMode={false}
      metaFileDirectory={'.'}>
      <div className="container body">
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                apContext.isLoggedIn ? <Navigate to={"/dashboard/dashboard"} /> : <Login />
              }
            />
            <Route path="/dashboard/*" element={<Layout />} />
          </Routes>
        </Router>
      </div>
    </CacheBuster>
  );
}

export default App;
