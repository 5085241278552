import { Message } from "primereact/message";
import React, { FC } from "react";

interface ErrorMessageProps {
  errorText?: string;
}
const ErrorMessage:FC<ErrorMessageProps> = ({ errorText }) => {
  return <Message severity="error" text={errorText} />;
};

export default ErrorMessage;
