import { Card } from "primereact/card";
import React, {useEffect} from "react";

const AddCredit = () => {

    useEffect(() => {
        const rzpPaymentForm = document.getElementById("rzp_payment_form");
    
        if (!rzpPaymentForm?.hasChildNodes()) {

            const script = document.createElement("script");
            script.src = "https://checkout.razorpay.com/v1/payment-button.js";
            script.async = true;
            script.dataset.payment_button_id = "pl_Ob9GHJbS8fEPFO";
            rzpPaymentForm?.appendChild(script);

        }
    })
    
    return(
        <>
            <Card className="flex flex-1 align-content-center">
                <p className="text-l font-normal my-0 pb-2">Add Balance</p>
                <form id="rzp_payment_form"></form>
            </Card>
        </>
    )
}

export default AddCredit;
