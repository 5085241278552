// Slideshow.tsx
import React, { useState, useEffect } from 'react';
import './Slideshow.scss'; // Import SCSS file for styling

const Slideshow: React.FC = () => {
  const [slides] = useState([
    {
      image: '/image/Login/01.jpg',
      text: 'Direct your marketing spend to the right farmers'
    },
    {
      image: '/image/Login/02.jpg',
      text: 'Build great farmer relationships'
    },
    {
      image: '/image/Login/03.jpeg',
      text: 'Improve organizational performance through competitive intelligence'
    },
    {
      image: '/image/Login/04.webp',
      text: 'Identify and analyze needs of the farmer through customized surveys'
    }
  ]);
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((currentSlide + 1) % slides.length);
    }, 4000);

    return () => clearInterval(interval);
  }, [currentSlide, slides.length]);

  const handleIndicatorClick = (index: number) => {
    setCurrentSlide(index);
  };

  return (
    <div>
      <div className="slideshow-container">
        <div className="image-container">
          <img
            src={slides[currentSlide].image}
            alt={slides[currentSlide].text}
            className="slide-image"
            style={{ width: '400px', height: '300px' }} // Set fixed width and height
          />
        </div>
        <div className="text-container">
          <h1>{slides[currentSlide].text}</h1>
        </div>
      </div>
      <div className="indicators">
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`indicator ${index === currentSlide ? 'active' : ''}`}
            onClick={() => handleIndicatorClick(index)}
          ></div>
        ))}
      </div>
      <div className="indicator-dots">
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`indicator-dot ${index === currentSlide ? 'active' : ''}`}
            onClick={() => handleIndicatorClick(index)}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default Slideshow;
