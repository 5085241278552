import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppContext } from "../../providers/AppContext";
import {
  executeAdCampaign,
  getAdCampaignById,
  reviewAdCampaign,
} from "../../services/AdCampaignService";
import { IAdCampaignDetail } from "../../services/models/AdCampaignByIdResponse";
import "./AdCampaignDetails.scss";
import UrlConstants from "../../constants/ApiUrls";
import { Card } from "primereact/card";

const AdCampaignDetails = () => {
  const appContext = useAppContext();
  const routeParams = useParams();

  const columns = [
    { codeColumn: "row", nameColumn: "Code" },
    { codeColumn: "data", nameColumn: "Name" },
  ];

  const [adCampaign, setAdCampaign] = useState<IAdCampaignDetail | null>(null);
  const [summaryData, setSummaryData] = useState<any[]>([]);
  const [reviewDate, setReviewDate] = useState<string>("");
  const [executionDate, setExecutionDate] = useState<string>("");
  const [creationDate, setCreationDate] = useState<string>("");

  const [MessagesSent, SetMessagesSent] = useState<string>("");
  const [MessagesDelivered, SetMessagesDelivered] = useState<string>("");
  const [MessagesRead, SetMessagesRead] = useState<string>("");

  const getAdCampaignDetail = (adCampaignId: string) => {
    getAdCampaignById(adCampaignId)
      .then((res) => {
        if (res?.status === 200) {
          setState(res?.data);
        }
      })
      .catch((res) => { });
  };

  const setState = (data: IAdCampaignDetail) => {
    setAdCampaign(data);
    setSummaryData([
      {
        id: 1,
        row: "Title:",
        data: data?.AdCampaignBasicDetail?.AdCampaignTitle,
        color: "black",
      },
      {
        id: 2,
        row: "Description:",
        data: data?.AdCampaignBasicDetail?.AdCampaignDescription,
        color: "black",
      },
      {
        id: 3,
        row: "Target - Geography:",
        data: `${data?.AdCampaignTargetAudience?.TargetAudience?.Geography?.Country} - ${data?.AdCampaignTargetAudience?.TargetAudience?.Geography?.State} - ${data?.AdCampaignTargetAudience?.TargetAudience?.Geography?.District} - ${data?.AdCampaignTargetAudience?.TargetAudience?.Geography?.SubDistrict}`,
        color: "black",
      },
      {
        id: 4,
        row: "Target - Commodity:",
        data: data?.AdCampaignTargetAudience?.TargetAudience?.Commodity,
        color: "black",
      },
      {
        id: 5,
        row: "Content - Text:",
        data: data?.AdCampaignContent?.ContentText,
        color: "black",
      },
      {
        id: 6,
        row: "Content - Media:",
        data: data?.AdCampaignContent?.ContentMediaName,
        hrefValue: data?.AdCampaignContent?.ContentMedia,
        color: "blue",
      },
      {
        id: 7,
        row: "Campaign Channel:",
        data: data?.AdCampaignTargetAudience?.ReachOutTarget?.CampaignChannel,
        color: "black",
      },
      {
        id: 8,
        row: "Target Farmers:",
        data: data?.AdCampaignTargetAudience?.ReachOutTarget?.TargetedFarmers,
        color: "black",
      },
      {
        id: 9,
        row: "Price:",
        data: `INR ${data?.AdCampaignTargetAudience?.ReachOutTarget?.Price}`,
        color: "black",
      },
    ]);

    if (
      (data as IAdCampaignDetail)?.MetaData?.AdCampaignExecutionDetail
        ?.AdCampaignExecution?.AdCampaignExecutedBy
    ) {
      setExecutionDate(
        (data as IAdCampaignDetail)?.MetaData?.AdCampaignExecutionDetail
          ?.AdCampaignExecution?.AdCampaignExecutedTimestamp
      );
    }
    if (
      (data as IAdCampaignDetail)?.MetaData?.AdCampaignReviewDetail
        ?.AdCampaignReview?.AdCampaignReviewedBy
    ) {
      setReviewDate(
        (data as IAdCampaignDetail)?.MetaData?.AdCampaignReviewDetail
          ?.AdCampaignReview?.AdCampaignReviewedTimestamp
      );
    }
    if (
      (data as IAdCampaignDetail)?.MetaData?.AdCampaignCreationDetail
        ?.AdCampaignCreation?.AdCampaignCreatedTimestamp
    ) {
      setCreationDate(
        (
          data as IAdCampaignDetail
        )?.MetaData?.AdCampaignCreationDetail?.AdCampaignCreation?.AdCampaignCreatedTimestamp?.toString()
      );
    }

    const adCampaignResponseDetails = (data as IAdCampaignDetail)
      ?.AdCampaignResponseDetails?.AdCampaignResponseDetails?.Whatsapp;

    if (adCampaignResponseDetails) {
      SetMessagesSent(adCampaignResponseDetails.MessagesSent?.toString() || "");
      SetMessagesDelivered(
        adCampaignResponseDetails.MessagesDelivered?.toString() || ""
      );
      SetMessagesRead(adCampaignResponseDetails.MessagesRead?.toString() || "");
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (routeParams?.id) {
      getAdCampaignById(routeParams?.id)
        .then((res) => {
          if (res?.status === 200) {
            setState(res?.data);
          }
        })
        .catch((res) => { });
    }
  }, [routeParams?.id]);

  const onReviewAdCampaign = () => {
    if (adCampaign?.AdCampaignBasicDetail?.AdCampaignID) {
      appContext.showLoader();
      reviewAdCampaign(adCampaign?.AdCampaignBasicDetail?.AdCampaignID)
        .then((res) => {
          appContext.showToast("AdCampaign Reviewed successfully", "success");
          getAdCampaignDetail(adCampaign?.AdCampaignBasicDetail?.AdCampaignID);
        })
        .catch((res) => {
          appContext.showToast("Error in AdCampaign Reviewed", "error");
        })
        .finally(() => {
          appContext.hideLoader();
        });
    }
  };

  const onExecuteAdCampaign = () => {
    if (adCampaign?.AdCampaignBasicDetail?.AdCampaignID) {
      appContext.showLoader();
      executeAdCampaign(adCampaign?.AdCampaignBasicDetail?.AdCampaignID)
        .then((res) => {
          appContext.showToast("AdCampaign Executed successfully", "success");
          getAdCampaignDetail(adCampaign?.AdCampaignBasicDetail?.AdCampaignID);
        })
        .catch((res) => {
          appContext.showToast("Error in AdCampaign Executed", "error");
        })
        .finally(() => {
          appContext.hideLoader();
        });
    }
  };

  const showReviewButton = () => {
    const storage = localStorage.getItem("auth");
    if (storage) {
      const jsonState = JSON.parse(storage);

      return jsonState?.user?.ModuleAccess?.includes("Review");
    }

    return false;
  };

  const showExecuteButton = () => {
    const storage = localStorage.getItem("auth");
    if (storage) {
      const jsonState = JSON.parse(storage);

      return jsonState?.user?.ModuleAccess?.includes("Execute");
    }
  };

  return (
    <div className="checkoutLayout flex p-3">
      <span className="flex text-4xl font-semibold pb-3">Summary</span>
      <Card>
        {summaryData.map((data) =>
          <div className="flex w-full align-items-center">
            <div className="w-2">
              <p className="my-2">{data.row}</p>
            </div>
            <div className="w-full">
              {(data?.row === "Content - Media:") ?
                (<a href={`${process.env.REACT_APP_S3_BUCKET_URL}${data?.hrefValue}`}
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    color: data?.color,
                  }}
                >
                  {data.data}
                </a>
                ) : (<div className="font-semibold txt-clr-black">{data.data}</div>)}
            </div>
          </div>
        )}
        <div className="mt-4">
          <span className="text-xl font-semibold txt-clr-black">Timeline</span>
          <div className="flex w-full align-items-center mt-3">
            <div className="w-2">
              <p className="my-2">Creation Date :</p>
            </div>
            <div className="w-full font-semibold txt-clr-black">
              {creationDate
                ? new Date(Number(creationDate)).toDateString()
                : " - "}
            </div>
          </div>
          <div className="flex w-full align-items-center">
            <div className="w-2">
              <p className="my-2">Review Date :</p>
            </div>
            <div className="w-full font-semibold txt-clr-black">
              {reviewDate ? new Date(Number(reviewDate)).toDateString() : " - "}
            </div>
          </div>
          <div className="flex w-full align-items-center">
            <div className="w-2">
              <p className="my-2">Execution Date :</p>
            </div>
            <div className="w-full font-semibold txt-clr-black">
              {executionDate
                ? new Date(Number(executionDate)).toDateString()
                : " - "}
            </div>
          </div>
        </div>
        <div className="mt-4">
          <span className="text-xl font-semibold txt-clr-black">Response details</span>
          <div className="flex w-full align-items-center mt-3">
            <div className="w-2">
              <p className="my-2">Sent: </p>
            </div>
            <div className="w-full font-semibold txt-clr-black">
              {MessagesSent ? MessagesSent : 0}
            </div>
          </div>
          <div className="flex w-full align-items-center">
            <div className="w-2">
              <p className="my-2">Delivered: </p>
            </div>
            <div className="w-full font-semibold txt-clr-black">
              {MessagesDelivered ? MessagesDelivered : 0}
            </div>
          </div>
          <div className="flex w-full align-items-center">
            <div className="w-2">
              <p className="my-2">Read: </p>
            </div>
            <div className="w-full font-semibold txt-clr-black">
              {MessagesRead ? MessagesRead : 0}
            </div>
          </div>
        </div>
      </Card>
      <div className="py-3">
        {showReviewButton() && (
          <Button
            label="Review"
            className="btn-primary mr-4"
            disabled={!!reviewDate?.length}
            onClick={onReviewAdCampaign}
          ></Button>
        )}

        {showExecuteButton() && (
          <Button
            label="Execute"
            className="btn-primary mr-4"
            disabled={!!executionDate?.length}
            onClick={onExecuteAdCampaign}
          ></Button>
        )}

        <Button
          label="Back"
          className="btn-primary"
          onClick={() => {
            navigate("/dashboard/campaign-list");
          }}
        />
      </div>
    </div>
  );
};

export default AdCampaignDetails;
