


import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { MenuItem } from "primereact/menuitem";
import { FC } from "react";
import "./BreadCrumbs.scss";

interface BreadCrumbsProps {
  currentStep: number;
  items: any;
  setStep: (stepIndex: number) => void;
  completedSteps: number[];
}
const BreadCrumbs: FC<BreadCrumbsProps> = ({ setStep, completedSteps }) => {
  const changeRouting = (stepIndex: number) => {
    setStep(stepIndex);
  };

  const iconItemTemplate = (item: any, options: any) => {
    let isCompleted = completedSteps.includes(item.data - 1)
    return (
      // <a
      //   className={options.className}
      //   href="!#"
      //   onClick={($event) => {
      //     $event.stopPropagation();
      //     $event.preventDefault();
      //   }}
      // >
      //   <Button
      //     label={item.label}
      //     className={item.labelone}
      //     onClick={() => {
      //       if (completedSteps.includes(3)) {
      //         changeRouting(item.data - 1);
      //       }
      //     }}
      //     disabled={
      //       !completedSteps.includes(item.data - 1) &&
      //       !completedSteps.includes(3)
      //     }
      //   ></Button>
      // </a>
      <span onClick={() => {
        if (item.data == 4 || isCompleted) {
          if (completedSteps.includes(3)) {
            changeRouting(item.data - 1);
          }
        }
      }}>{item.label}</span>
    );
  };
  const item: MenuItem[] = [
    {
      label: "Target Audience",
      template: iconItemTemplate,
      data: 2,
    },

    {
      label: "Content",
      template: iconItemTemplate,
      data: 3,
    },

    {
      label: "Summary",
      template: iconItemTemplate,
      data: 4,
    },


  ];

  const home: MenuItem = {
    label: "Campaign Setup ",
    template: iconItemTemplate,
    data: 1,
  };

  return (
    <BreadCrumb
      model={item}
      home={home}
      unstyled={false}
      pt={{
        label: { className: "labelone" },
        icon: { className: "labelone" },
      }}
    />
  );
};
export default BreadCrumbs;
