import UrlConstants from "../constants/ApiUrls";
import axios from "./ApiService";
import {
  ICreateAdCampaignRequest,
  IUpdateAdCampaignRequest,
} from "./models/createAdCampaign";
import { IAdCampaignContent } from "./models/createAdCampaignContent";
import { ICreateTargetAudienceRequestModel } from "./models/createTargetAudienceRequestModel";
import { IGetChannelDetailsRequest } from "./models/getChannelDetailsRequest";

export const getAllAdCampaignList = (userId: string) => {
  return axios({
    url: UrlConstants.ADCAMPAIGN.LIST.replace(":userId", userId),
    method: "get",
    withCredentials: true,
  });
};

export const getGeographyAndCommodityList = () => {
  return axios({
    url: UrlConstants.ADCAMPAIGN.GET_GEOGRAPHY_AND_COMMODITY_LIST,
    method: "get",
    withCredentials: true,
  });
};

export const getAdCampaignChannelDetails = (
  params: IGetChannelDetailsRequest
) => {
  return axios({
    url: UrlConstants.ADCAMPAIGN.GET_AD_CAMPAIGN_CHANNEL_DETAILS.replace(
      ":country",
      params.country
    )
      .replace(":state", params.state)
      .replace(":district", params.district)
      .replace(":subdistrict", params.subdistrict)
      .replace(":commodity", params.commodity),
    method: "get",
    withCredentials: true,
  });
};

export const createAdCampaign = (model: ICreateAdCampaignRequest) => {
  return axios({
    url: UrlConstants.ADCAMPAIGN.CREATE,
    method: "post",
    data: model,
    withCredentials: true,
  });
};

export const updateAdCampaign = (model: IUpdateAdCampaignRequest) => {
  return axios({
    url: UrlConstants.ADCAMPAIGN.UPDATE,
    method: "put",
    data: model,
    withCredentials: true,
  });
};

export const reviewAdCampaign = (adCampaignId: string) => {
  return axios({
    url: UrlConstants.ADCAMPAIGN.REVIEW,
    method: "post",
    data: { adCampaignId: adCampaignId },
    withCredentials: true,
  });
};

export const submitAdCampaign = (adCampaignId: string) => {
  return axios({
    url: UrlConstants.ADCAMPAIGN.SUBMIT,
    method: "post",
    data: { adCampaignId: adCampaignId },
    withCredentials: true,
  });
};

export const executeAdCampaign = (adCampaignId: string) => {
  return axios({
    url: UrlConstants.ADCAMPAIGN.EXECUTE,
    method: "post",
    data: { adCampaignId: adCampaignId },
    withCredentials: true,
  });
};

export const createAdCampaignTargetAudience = (
  model: ICreateTargetAudienceRequestModel
) => {
  return axios({
    url: UrlConstants.ADCAMPAIGN.CREATE_TARGET_AUDIENCE,
    method: "post",
    data: model,
    withCredentials: true,
  });
};

export const createAdCampaignContent = (model: IAdCampaignContent) => {
  return axios({
    url: UrlConstants.ADCAMPAIGN.CREATE_CONTENT,
    method: "post",
    data: model,
    withCredentials: true,
  });
};

export const getAdCampaignById = (adCampaignId: string) => {
  return axios({
    url: UrlConstants.ADCAMPAIGN.GET_BY_ID.replace(
      ":adCampaignId",
      adCampaignId
    ),
    method: "get",
    withCredentials: true,
  });
};

export const getChanelRate = () => {
  return axios({
    url: UrlConstants.ADCAMPAIGN.GET_ADD_CAMPAIGN_CHANNEL_RATE,
    method: "get",
    withCredentials: true,
  });
};
