import "primeicons/primeicons.css";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { getAllAdCampaignList } from "../../services/AdCampaignService";
import Colors from "../../theme/variable";
import "./AdCampaignList.scss";
import { useAppContext } from "../../providers/AppContext";
import { Card } from "primereact/card";
import moment from "moment";

const AdCampaignList = () => {
  const navigate = useNavigate();
  const appContext = useAppContext();
  const [adCampaigns, setAdCampaigns] = useState([]);

  useEffect(() => {
    const storage = localStorage.getItem("auth");
    if (storage) {
      const jsonState = JSON.parse(storage);
      if (jsonState) {
        appContext.showLoader();

        getAllAdCampaignList(jsonState?.user?.userID)
          .then((res) => {
            if (res?.data) {
              const items = res?.data?.map((d: any) => {
                let status = "";

                if (d?.AdCampaignExecutionDetail) {
                  status = "Executed";
                } else if (d?.AdCampaignReviewDetail) {
                  status = "Reviewed";
                } else if (d?.AdCampaignSubmitDetail) {
                  status = "Review - In Progress";
                } else if (d?.AdCampaignCreationDetail) {
                  status = "In Progress";
                }

                // set creation date
                const creationDate = d?.AdCampaignCreationDetail?.AdCampaignCreation?.AdCampaignCreatedTimestamp ?
                  moment(d?.AdCampaignCreationDetail?.AdCampaignCreation?.AdCampaignCreatedTimestamp).format("DD MMM YYYY, hh:mm A")
                  : "-";

                return {
                  ...d.AdCampaignBasicDetail,
                  Status: status,
                  CreationDate: creationDate,
                };
              });
              setAdCampaigns(items);
            }
          })
          .catch((err) => {
            appContext.hideLoader();
            console.log(err);
          })
          .finally(() => {
            appContext.hideLoader();
          });
      }
    }
  }, [appContext]);

  const handleStatus = (rowData: any) => {
    const statusColor = getStatusColor(rowData?.Status);
    return (
      <div>
        {rowData?.Status === "Review - Rejected" ? (
          <i className="pi pi-exclamation-circle pr-2" />
        ) : (
          <i className="pi pi-check-circle pr-2" style={{ color: statusColor }} />
        )}
        <span style={{ color: statusColor }} className="row-status">
          {rowData?.Status}
        </span>
      </div>
    );
  };

  /*  getStatusColor function added to distinguish between different status colors
      Color variables is used for colors.  
  */
  const getStatusColor = (status: string) => {
    switch (status) {
      case "Review - In Progress":
        return Colors.primary;
      case "Review - Rejected":
        return Colors.lightPink;
      case "Executed":
        return Colors.seaBlue;
      case "In Progress":
        return Colors.seaBlue;
      default:
        return Colors.black;
    }
  };

  const handleCampaignSelect = (adCampaign: any) => {
    if (adCampaign?.Status !== "In Progress") {
      navigate(`/dashboard/campaign-details/${adCampaign?.AdCampaignID}`);
    } else {
      navigate(`/dashboard/new-campaign/${adCampaign?.AdCampaignID}`);
    }
  };

  const campaignIdTemplate = (rowData: any) => {
    return (
      <button className="p-link" onClick={() => handleCampaignSelect(rowData)}>
        {rowData?.AdUIID}
      </button>
    );
  };

  return (
    <div className="campaignListPage">
      <div className="flex w-full justify-content-between align-items-center pb-3">
        <span className="flex text-4xl font-semibold">Ad Campaigns</span>
        <Button
          className="btn-primary"
          label="New"
          icon="pi pi-plus"
          onClick={() => navigate("/dashboard/new-campaign/create")}
        />
      </div>

      <Card>
        <DataTable
          className="rp-table"
          value={adCampaigns ? adCampaigns : []}
          paginator
          rows={10}
          scrollHeight="70vh"
          scrollable
          rowsPerPageOptions={[10, 20, 30, 40]}
          sortField="AdUIID" // Specify the field to sort by
          sortOrder={-1} // Set sortOrder to -1 for descending order
        >
          <Column
            field="AdUIID"
            header="Campign ID"
            style={{ color: Colors.black }}
            body={campaignIdTemplate}
            sortable={true}
          ></Column>
          <Column
            style={{ color: Colors.black }}
            field="AdCampaignTitle"
            header="Title"
          ></Column>
          <Column
            style={{ color: Colors.black }}
            field="Status"
            header="Status"
            body={handleStatus}
          ></Column>
          <Column
            style={{ color: Colors.black }}
            field="CreationDate"
            header="Campaign Date"
          ></Column>
        </DataTable>
      </Card>
    </div>
  );
};

export default AdCampaignList;
