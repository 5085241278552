export const getFileNameFromPath = (path: string) => {
  var el = document.createElement("a");
  el.href = path;
  return el.pathname.substring(el.pathname.lastIndexOf("/") + 1);
};

export const getFileMimeType = (filename: string) => {
  const extension = filename?.split(".")?.pop()?.toLowerCase();
  switch (extension) {
    // Image file extensions
    case "jpg":
    case "jpeg":
      return "image/jpeg";
    case "png":
      return "image/png";
    case "gif":
      return "image/gif";
    case "bmp":
      return "image/bmp";
    case "webp":
      return "image/webp";
    case "svg":
      return "image/svg+xml";

    // Audio file extensions
    case "mp3":
      return "audio/mpeg";
    case "wav":
      return "audio/wav";
    case "ogg":
      return "audio/ogg";
    case "aac":
      return "audio/aac";
    case "flac":
      return "audio/flac";
    case "m4a":
      return "audio/mp4";

    // Video file extensions
    case "mp4":
      return "video/mp4";
    case "webm":
      return "video/webm";
    case "ogv":
      return "video/ogg";
    case "avi":
      return "video/x-msvideo";
    case "mov":
      return "video/quicktime";
    case "mkv":
      return "video/x-matroska";
    case "wmv":
      return "video/x-ms-wmv";
    case "flv":
      return "video/x-flv";
    case "3gp":
      return "video/3gpp";

    // Default MIME type for unknown extensions
    default:
      return "application/octet-stream";
  }
};

export const getFileExtension = (filename: string) => {
  return filename?.split(".")?.pop()?.toLowerCase();
};
