import UrlConstants from "../constants/ApiUrls";
import axios from "./ApiService";

export const getPreSignedUrl = (photoData: any) => {
  let url = `${UrlConstants.UPLOAD_FILE.GET_PRESIGNED_URL}`;
  const params = new URLSearchParams(photoData).toString();
  url += params;
  const promise = new Promise((resolve, reject) => {
    axios
      .get(url, { withCredentials: true })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error?.response || error);
      });
  });
  return promise;
};

// export const fetchUploadedImage = (photoData: any) => {
//   let url = `${API_URL.DOWNLOAD_PHOTO_URL}`;
//   const params = new URLSearchParams(photoData).toString();
//   url += params;
//   const promise = new Promise((resolve, reject) => {
//     checkConnection()
//       .then(() => axiosInstance.get(url, { withCredentials: true }))
//       .then(({ data }) => {
//         resolve(data);
//       })
//       .catch((error) => {
//         reject(error?.response || error);
//       });
//   });
//   return promise;
// };

export const getBlob = async (fileUri: any) => {
  const resp = await fetch(fileUri);
  const imageBody = await resp.blob();
  return imageBody;
};

export const photoUpload = async (url: any, imageBlob: Blob) => {
  return fetch(url, {
    method: "PUT",
    body: imageBlob,
    mode: "cors",
    headers: {
      "Content-Type": "image/jpeg",
      accept: "application/json",
    },
  });
};
