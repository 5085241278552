import { FC, useEffect } from "react";
import Header from "../components/header/Header";
import Sidebar from "../components/sidebar/Sidebar";
import { Route, Routes, useNavigate } from "react-router-dom";
import AdCampaignList from "./AdCampaignList/AdCampaignList";
import CreateNewCampaign from "./CreateNewCampaign";
import { useAppContext } from "../providers/AppContext";
import AdCampaignDetails from "./AdCampaignDetails/AdCampaignDetails";
import Dashboard from "./Dashboard";
import MessageCredit from "./MessageCredit";
import { UserLocalStorage } from "../services/models/user/user";

const Layout: FC<{}> = () => {
  const navigate = useNavigate();
  const appContext = useAppContext();
  const authData: UserLocalStorage = JSON.parse(localStorage.getItem('auth') || '')

  useEffect(() => {
    if (!appContext.isLoggedIn) {
      navigate("/");
    }
  }, [navigate, appContext.isLoggedIn]);
  return (
    <div className="flex flex-column hw-full">
      <Header />
      <div className="flex hw-full">
        <div className="w-2">
          <Sidebar />
        </div>
        <div className="w-full p-3 content">
          <Routes>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="message-credit" element={<MessageCredit />} />
            <Route path="campaign-list" element={<AdCampaignList />} />
            <Route path="new-campaign/:id" element={<CreateNewCampaign />} />
            <Route path="campaign-details/:id" element={<AdCampaignDetails />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default Layout;
