import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { FC } from "react";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import "./Dropdown.scss";

interface DropdownMenuProps {
  options: any;
  optionLabel: string;
  placeholder: string;
  onChange?: (e: DropdownChangeEvent) => void;
  value?: any;
  errorText?: string;
  id: string;
}

const DropdownMenu: FC<DropdownMenuProps> = ({
  options,
  optionLabel,
  placeholder,
  onChange,
  value,
  errorText,
  id
}) => {
  return (
    <div style={{ marginRight: "20px", marginBottom: "10px", width: "200px"
     }}>
      <Dropdown
        id={id}
        value={value}
        onChange={onChange}
        options={options}
        optionLabel={optionLabel}
        placeholder={placeholder}
        className="w-full input-dropdown"
        style={{ width: "100%",height: "36px" }} 
      />
      <ErrorMessage errorText={errorText} />
    </div>
  );
};

export default DropdownMenu;
