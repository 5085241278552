import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { FC, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import ErrorMessage from "../../../components/ErrorMessage/ErrorMessage";
import {
  contentValidation,
  editModeContentValidation,
} from "../../../utils/form-validation";
import "./Content.scss";
import { IAdCampaignContent } from "../../../services/models/AdCampaignByIdResponse";
import { useNavigate } from "react-router-dom";
import { getFileMimeType, getFileNameFromPath } from "../../../constants/util";
import { Card } from "primereact/card";
import { Divider } from "primereact/divider";

interface ContentProps {
  handleBack: () => void;
  handleRouting: () => void;
  currentStep: number;
  items: any;
  handleFormSubmit: any;
  existingValue: IAdCampaignContent | undefined;
}
const Content: FC<ContentProps> = ({
  handleBack,
  handleRouting,
  currentStep,
  items,
  handleFormSubmit,
  existingValue,
}) => {
  const [file, setFile] = useState<any>();
  const [fileName, setFileName] = useState<string>();
  const [mimeType, setFileMimeType] = useState<string>();
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      existingValue?.AdCampaignID
        ? editModeContentValidation
        : contentValidation
    ),
  });

  useEffect(() => {
    if (existingValue) {
      setValue("Text", existingValue?.ContentText);
      setFile(existingValue?.ContentMedia);
      setFileName(existingValue?.ContentMediaName);
      setFileMimeType(
        getFileMimeType(getFileNameFromPath(existingValue?.ContentMedia))
      );
    }
  }, [existingValue, setValue]);

  const onSubmit: SubmitHandler<any> = (data) => {
    if (file) {
      data.Media = file;
    } else {
      data.Media = existingValue?.ContentMedia;
    }

    data.fileName = fileName;
    handleFormSubmit(data);
    //handleRouting(); - Deleted this line
  };

  const handleChange = (e: any) => {
    setFile(URL.createObjectURL(e?.target?.files[0]));
    setFileName(e?.target?.files[0]?.name);

    const mimeType = e?.target?.files[0]?.type;
    setFileMimeType(mimeType);
  };

  return (
    <>
      <div className="contentLayout " id="nofooter">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex mt-3">
            <div className="flex1">
              <Card>
                <div className="txt-clr-black font-medium txt-lg">Contents for your campaign</div>
                <Divider />
                <label
                  htmlFor="Text"
                  className="txt-clr-black"
                > Text
                </label>
                <div className="mt-3">
                  <Controller
                    defaultValue={""}
                    name="Text"
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (

                      <InputTextarea
                        id="Text"
                        className="customTextarea"
                        onBlur={onBlur}
                        cols={30}
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                </div>

                <div className="mb-2">
                  <ErrorMessage errorText={errors?.Text?.message} />
                </div>
              </Card>

              <Card className="mt-3">
                <label
                  htmlFor="Media"
                  className="txt-clr-black"
                >
                  Media
                </label>
                <Divider />
                <div className="file-upload">
                  <div className="file-input">
                    <Controller
                      defaultValue={""}
                      name="Media"
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <>
                          <input
                            type="file"
                            id="Media"
                            onBlur={onBlur}
                            value={value}
                            onChange={onChange}
                            onChangeCapture={handleChange}
                          />
                        </>
                      )}
                    />
                  </div>
                </div>
                <ErrorMessage errorText={errors?.Media?.message} />
              </Card>

              <div className="mt-4" id="footer">
                <Button
                  label="Close"
                  className="btn-secondary mr-3"
                  onClick={() => {
                    navigate("/dashboard/campaign-list");
                  }}
                />
                <Button label="Back" className="btn-secondary mr-3" onClick={handleBack} />
                <Button
                  label="Next"
                  className="btn-primary mr-3"
                  type="submit"
                  disabled={currentStep === items?.length - 1}
                />
              </div>
            </div>
            <div className="ml-3">
              <div>
                {file?.length > 0 && mimeType?.includes("image") && (
                  <img src={file} className="uploadImage" alt={file?.name} />
                )}

                {file?.length > 0 && mimeType?.includes("video") && (
                  <video width="320" height="240" controls>
                    <source src={file} type={mimeType} />
                  </video>
                )}

                {file?.length > 0 &&
                  !(mimeType?.includes("video") || mimeType?.includes("image")) && (
                    <a href={file}>{fileName}</a>
                  )}
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Content;