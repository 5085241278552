import { NavLink, useNavigate } from "react-router-dom";
import "./Sidebar.scss";

import { Card } from "primereact/card";

const Sidebar = () => {

  const nav = useNavigate();

  return (
    <Card className="sidebar w-full h-full">
      <NavLink className="item align-items-center" to='/dashboard/dashboard'><i className="pi pi-home pr-2"></i> Dashboard</NavLink>
      <NavLink className="item align-items-center" to='/dashboard/campaign-list'><i className="pi pi-list pr-2"></i> Ad Campaings</NavLink>
      <div className="flex align-items-center mt-2">
        <div>
          <i className="pi pi-chart-bar pr-2 ml-_75"></i>
        </div>
        <div>
          <p className="m-0 text-sm">Survey</p>
          <p className="m-0 text-sm">(Coming Soon)</p>
        </div>
      </div>
      <div className="flex align-items-center mt-2">
        <div>
          <i className="pi pi-dollar pr-2 ml-_75"></i>
        </div>
        <div>
          <p className="m-0 text-sm">Market Intelligence</p>
          <p className="m-0 text-sm">(Coming Soon)</p>
        </div>
      </div>
    </Card>
  );
};

export default Sidebar;
