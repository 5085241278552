import { Card } from "primereact/card";
import { Chart } from "primereact/chart";
import React, { useEffect, useState } from "react";

export interface DashboardChartProps {
    msgSent: number[],
    msgDelivered: number[],
    msgRead: number[],
    dates: number[],
}

const DashboardChart = (props: DashboardChartProps) => {

    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    useEffect(() => {
        const data = {
            labels: props.dates,
            datasets: [
                {
                    label: "Sent",
                    data: props.msgSent,
                    backgroundColor: ['#BA68C8'],
                    borderColor: ['#7B1FA2'],
                    borderWidth: 1
                },
                {
                    label: "Delivered",
                    data: props.msgDelivered,
                    backgroundColor: ['#03A9F4'],
                    borderColor: ['#01579B'],
                    borderWidth: 1
                },
                {
                    label: "Read",
                    data: props.msgRead,
                    backgroundColor: ['#AED581'],
                    borderColor: ['#689F38'],
                    borderWidth: 1
                }
            ]
        };
        const options = {
            responsive: true,
            maintainAspectRatio: false,
            elements: {
                line: {
                    tension : 0.4  // smooth lines
                },
            },
            scales: {
                y: {
                    title:{
                        text: "No. of Messages",
                        display: true,
                        font:{
                            size: 16
                        },
                    },
                    grid: {
                        display: false
                    },
                    beginAtZero: true,
                },
                x: {
                    grid: {
                        display: false,
                    }
                }
            }
        };

        setChartData(data);
        setChartOptions(options);
    }, [props.dates]);

    return (
        <div className="card pb-3">
            <p className="text-xl font-normal"><i className="pi pi-chart-line pr-2"></i>Daily Statics for Ad Campaign</p>
            { props.dates.length > 0 && 
                <Card>
                    <Chart type="line" className="dashChart" data={chartData} options={chartOptions} />
                </Card>
            }

            {/* <Card>
                <Chart type="bar" className="dashChart" data={chartData} options={chartOptions} />
            </Card> */}
        </div>
    )
}

export default DashboardChart;